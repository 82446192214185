.gc-loading-image {
	text-align: center;
	padding: 60px 15px;
	p {
		margin-top: 30px;
	}
}

#class-search-bar {
	width: 100%;
	text-align: center;
	.greyBlock & {
		h1, a {
			color: $companyBlue;
		}
	}
	.blueBlock & {
		h1 {
			color: white;
		}
		a {
			color: $Yellow;
		}
	}
	h1 {
		font-family: 'SteelFish', sans-serif;
		font-weight: 400;
		margin-bottom: 16px;
		text-transform: uppercase;
	}
	p {
		color: $textLightGrey;
		a {
			color: $companyBlue;
		}
	}
	.form {
		#Input-location-container {
			position: relative;
			input {
				width:100%;
			}
			&:after {
				position: absolute;
				top: 0;
				top: calc(50% - 12px);
				left: 15px;
				width: 20px;
				content: "l";
				font-family: 'Boxercise';
				color: $companyBlue;
			}
		}
		& > span.radio-container {
			display: flex;
			.radio {
				flex: 1;
				position: relative;
				input[type="radio"], .radio-text {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				input[type="radio"] {
					z-index: 3;
					opacity: 0;
				}
				.radio-text {
					z-index: 1;
					background-color: white;
					color: $textGrey;
				}
				input[type="radio"]:checked ~ .radio-text {
					background-color: #273d87;
					color: white;
				}
			}
		}
		/*input[type="radio"] {
			position: relative;
			&:checked:after {
				background-color: #273d87;
				color: white;
			}
			&:after {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: white;
				color: $textGrey;
				opacity: 1;
				z-index: 2;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			&#Class {
				margin-right: 0;
				&:after {
					content: "Class";
				}
			}
			&#PT {
				margin-left: 0;
				&:after {
					content: "Personal Trainer";
				}
			}
		}	*/
	}
	.form input, select {
		position: relative;
		border: 1px solid #ddd;
	}
	select {
		border: none;
		color: #696969;
	}
	input[type="submit"] {
		@include button($companyBlue, $Yellow);
		border-color: $Yellow;
	}
}
#search-for-a-class {
	.content {
		display: flex;
		#map {
			flex: 3;
		}
	}
}
#class-search-results {
	flex: 2;
	.result {
		background-color: white;
		display: flex;
		position: relative;
		transition: all 0.4s ease;
		border-bottom: 1px solid #e6e6e6;
		.instructor-picture {
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}
		.text {
			.instructor {
				color: $textGrey;
				text-transform: uppercase;
				font-weight: 600;
			}
			.location {
				color: $companyBlue;
				font-weight: 600;
				width: 100%;
				max-height: 3em;
				overflow: hidden;
			}
			.dates {
				color: $companyBlue;
				font-weight: 400;
			}
		}
		.arrow {
			position: absolute;
			top: 33%;
			top: calc(50% - 11px);
			right: 40px;
			color: $companyBlue;
		}
		&:hover {
			transition: all 0.4s ease;
			z-index: 5;
			text-decoration: none;
			box-shadow: 0px 25px 73px 0px rgba(24, 24, 24, 0.25);
			transform: scale(1.01);
		}
	}
}

@media screen and (min-width: 992px) {
	#class-search-bar {
		padding-top: 80px;
		padding-bottom: 80px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		h1 {
			font-size: 60px;
			margin-bottom: 30px;
		}
		.form {
			display: flex;
			width: 100%;
			max-width: 1200px;
			padding-left: 15px;
			padding-right: 15px;
			& > select, & > input, & > span, {
				flex: 1;
				margin-left: 12px;
				margin-right: 12px;
			}
			select, input, span {
				height: 80px;
				border: none;
			}
			select, input {
				padding-left: 45px;
				border: 1px solid #ccc;
			}
			input[type="submit"] {
				flex: unset;
				width: 80px;
				padding: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: bold;
				font-size: 25px;
			}
			.mobile {
				display: none !important;
			}
		}
	}
	#class-search-results {
		.result {
			width: 100%;
			padding: 31px 40px;
			.instructor-picture {
				width: 80px;
				img {
					width: 60px;
				}
			}
			.text {
				flex: 1;
				.instructor {
					font-size: 15px;
					line-height: 1em;
					margin-bottom: 5px;
				}
				.location {
					font-size: 21px;
					margin-bottom: 0px;
				}
				.dates {
					font-size: 21px;
					margin-bottom: 0;
				}
			}
		}
	}
}

@media screen and (max-width: 991px) {
	#class-search-bar {
		padding: 40px 30px;
		form {
			.desktop {
				display: none !important;
			}
			.radio-container {
				height: 50px;
			}
		}
	}
}

@media screen and (max-width: 991px) and (min-width: 577px) {
	#class-search-bar {
		input, select, input[type="submit"] {
			padding: 15px 35px;
			margin-top: 7px;
			margin-bottom: 7px;
			font-size: 15px;
			border: 1px solid #ddd;
		}
		input[type="radio"] {
			height: 54px;
			&:after {
				text-align: center;
			}
		}
		input, input[type="submit"] {
			width: 100%;
		}
		#Input-location-container {
			&:after, &::after {
				top: 20px;
			}
		}
		select {
			width: 100%;
			border: 1px solid #ddd;
		}
	}
	#class-search-results {
		.result {
			width: 100%;
			padding: 15px 20px;
			.instructor-picture {
				width: 50px;
				img {
					width: 30px;
				}
			}
			.text {
				flex: 1;
				.instructor {
					font-size: 12px;
					line-height: 1em;
					margin-bottom: 5px;
				}
				.location {
					font-size: 15px;
					margin-bottom: 0px;
				}
				.dates {
					font-size: 15px;
					margin-bottom: 0;
				}
			}
			.arrow {
				font-size: 15px;
				top: calc(50% - 7px);
				right: 20px;
			}
		}
	}
}

@media screen and (max-width: 576px) {
	#class-search-bar {
		input, select, input[type="submit"] {
			padding: 15px 35px;
			margin-top: 7px;
			margin-bottom: 7px;
			width: 100%;
			font-size: 15px;
		}
		input[type="radio"] {
			height: 54px;
			&:after {
				text-align: center;
			}
		}
		select {
			padding: 15px 15px;
		}
		input[type="submit"] {
			font-weight: 700;
		}
	}
	#search-for-a-class {
		.content {
			display: flex;
			#map {
				display: none;
			}
		}
	}
	#class-search-results {
		flex: 1;
		background-color: white;
		.result {
			padding: 15px 0px;
			padding-right: 25px;
			margin-left: 30px;
			margin-right: 30px;
			.instructor-picture {
				width: 50px;
				img {
					width: 30px;
				}
			}
			.text {
				flex: 1;
				.instructor {
					font-size: 12px;
					line-height: 1em;
					margin-bottom: 5px;
				}
				.location {
					font-size: 15px;
					margin-bottom: 0px;
				}
				.dates {
					font-size: 15px;
					margin-bottom: 0;
				}
			}
			.arrow {
				font-size: 15px;
				top: calc(50% - 7px);
				right: 15px;
			}
		}
	}
}