// variables
$bgGrey: #eeeeee;
$lineGrey: #dddddd;
$companyBlue: #00306a;
$Yellow: #f5e22a;
$companyBlack: #01040e;
$textBlue: #00306a;
$textGrey: #666666;
$textLightGrey: #999999;
$textGreyPurple: #a0a0b8;
$textBabyPurple: #adc0fe;
$orange: #ffc000;

html {
		scroll-behavior: smooth;
}

body {
	scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
	body {
		scroll-behavior: auto;
	}
}

iframe {
	max-width: 100%;
}

* {
	font-family: 'Poppins', sans-serif;
	font-display: swap;
}

h1, h2 {
	font-family: 'SteelFish', sans-serif;
	font-weight: 700;
	color: $textBlue;
	letter-spacing: 0.025em;
}

h3 {
	letter-spacing: 0.025em;
}

h3.title {
	font-family: 'SteelFish', sans-serif;

	font-weight: 700;
	color: $textBlue;
	letter-spacing: 0.025em;
}

h1.title {
	font-size: 60px;
	text-transform: uppercase;
}

p, li {
	color: $textGrey;
	font-size: 18px;
	font-weight: 400;
	line-height: 1.66em;
}

.icon {
	font-family: 'Boxercise' !important;
	& > * {
		font-family: 'Boxercise' !important;
	}
}

.link {
	color: #007bff;
	cursor: pointer;
}

p.big {
	font-size: 21px;
}

p.small {
	font-size: 14px;
}

.red {
	color: red;
}

.small-spacer {
	width: 100%;
	padding-top: 30px;
}
.block {
	padding-top: 130px;
	padding-bottom: 130px;
}

.small-block {
	padding-top: 50px;
	padding-bottom: 50px;
}

.small-gap {
	display: inline-block;
	padding-top: 30px;
}

.clear {
	clear: both;
}

.centre {
	width: 100%;
	text-align: center;
}

.right {
	text-align: right;
}

.greyBlock {
	background-color: $bgGrey;
}

.whiteBlock {
	background-color: white;
}

.blueBlock {
	background-color: $companyBlue;
}

.yellowBlock {
	background-color: $Yellow;
}

@mixin button($textColor, $bgColor) {
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-ms-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
	display: inline-block;
	background-color: $bgColor;
	font-size: 18px;
	padding-top: 30px;
	padding-bottom: 30px;
	text-align: center;
	color: $textColor;
	border: 2px solid $bgColor;
	margin-bottom: 5px;
	&:hover {
		-webkit-transition: all 0.4s;
		-moz-transition: all 0.4s;
		-ms-transition: all 0.4s;
		-o-transition: all 0.4s;
		transition: all 0.4s;
		background-color: transparent;
		color: $bgColor;
		text-decoration: none;
	}
}

.redButton {
	@include button(white, red);
}

.greenButton {
	@include button(white, green);
}

.blueButton {
	@include button(white, $companyBlue);
}

.yellowButton {
	@include button($companyBlue, $Yellow);
}

.blueYellowButton {
	@include button($companyBlue, $Yellow);
}

.blueWhiteButton {
	@include button($companyBlue, white);
	border-color: $companyBlue;
	&:hover {
		background-color: $companyBlue;
	}
}

.smallButton {
	padding: 15px 30px;
}

.neatButton {
	padding: 10px 15px;
	cursor: pointer;
}

.breadcrumbs {
	padding-bottom: 30px;
	color: #5a6268;
}

.summary-table {
	width: 100%;
	max-width: 800px;
	thead {
		background-color: $companyBlue;
		color: white;
	}
	tr td, thead th {
		text-align: right;
		padding: 10px;
	}
	tr td:first-child, thead th:first-child {
		text-align: left;
	}
}

.container-fluid.header-container {
	display: flex;
	flex-wrap: wrap;
}

.header-half {
	&.picture {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		transition: all 0.4s ease;
	}
	&.video {
		position: relative;
		&:hover {
			cursor: pointer;
		}
		&:after {
			content: '';
			position: absolute;
			top: 30%;
			top: calc(50% - 70px);
			left: 30%;
			left: calc(50% - 70px);
			height: 140px;
			width: 140px;
			background-image: url('../assets/symbols/play-btn.png');
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}
	}
}

.price-before {
	text-decoration: line-through;
}

.price-after {
	color: #be0624;
	position: relative;
}

.instructor-price-callout {
	position: absolute;
	left: 0;
	top: -5px;
	font-size: 12px;
	line-height: 1em;
	white-space: nowrap;
}

.banner {
	background-size: cover;
	background-repeat: no-repeat;
	display: block;
	&:hover {
		text-decoration: none;
	}
}

#ui-datepicker-div {
	background-color: white;
	border-color: $companyBlue;
}

.success {
	background-color: #b8e49d;
	color: $companyBlue;
	padding: 10px;
}

.warning {
	background-color: #ffe1e1;
	color: black;
	padding: 10px;
}

.alert {
	background-color: #ffffe1;
	color: black;
	padding: 10px;
}

.success-flash {
	animation-name: success-flash;
	animation-duration: 0.4s;
}

#changeCountryModel {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(50, 50, 50, 0.4);
	z-index: 100;
	padding: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	.inner {
		position: relative;
		width: 100%;
		max-width: 600px;
		padding: 60px;
		background-color: white;
		border-radius: 30px;
		border: 5px solid #1e2f69;
		text-align: center;
		display: flex;
		flex-direction: column;
		select {
			color: #696969;
			background-color: white;
			padding-left: 45px;
			border: 1px solid #ccc;
			height: 80px;
		}
		.close {
			position: absolute;
			top: -15px;
			right: -15px;
			background-color: $Yellow;
			padding: 10px 15px;
			opacity: 1;
			border-radius: 15px;
		}
	}
}

#benefits-block {
	padding: 60px;
	background-size: cover;
	background-position: center right;
	margin-bottom: 80px;
	h3 {
		font-size: 36px;
		margin-bottom: 60px;
	}
}

#to-complete-block {
	h3 {
		font-size: 42px;
		text-transform: uppercase;
		color: white;
		margin-bottom: 60px;
	}
	.item {
		position: relative;
		font-size: 21px;
		font-weight: bold;
		color: white;
		padding-bottom: 20px;
		padding-left: 30px;
		margin-left: 60px;
		border-left: 1px solid white;
	}
	.number {
		position: absolute;
		left: -60px;
		top: 0px;
		color: $Yellow;
	}
	.content {

	}
}

.faq {
	position: relative;
	border-bottom: 1px solid #ccc;
	cursor: pointer;
	&:after {
		content: "▼";
		color: $companyBlue;
		position: absolute;
		top: 15px;
		right: 5px;
		font-size: 21px;
		transition: all 0.4s ease;
	}
	&.turnArrow {
		&:after {
			transform: rotate(180deg);
		}
	}
	h4 {
		color: $companyBlue;
		font-size: 21px;
		font-weight: 600;
		margin: 15px;
		margin-right: 30px;
		padding-top: 15px;
		padding-bottom: 15px;
	}
	p, ul {
		display: none;
		margin: 15px;
		padding: 0 15px;
	}
}

.info-container {
	color: $textGrey;
	border-bottom: 1px solid #e6e6e6;
	&:first-child {
		border-top: 1px solid #e6e6e6;
	}
	.info-title {
		position: relative;
		transition: all 0.4s ease;
		padding-left: 5px;
		&.open {
			background-color: $companyBlue;
			color: white;
			transition: 0.4s ease all;
			padding-left: 15px;
			cursor: pointer;
			.arrow {
				color: white;
				transform: rotate(90deg);
			}
		}
		&:hover {
			background-color: $companyBlue;
			color: white;
			transition: 0.4s ease all;
			padding-left: 15px;
			cursor: pointer;
			.arrow {
				color: white;
				right: 30px;
			}
		}
	}
	.info-desc {
		display: none;
	}
	.arrow {
		position: absolute;
		top: 33%;
		top: calc(50% - 11px);
		right: 40px;
		color: #1e2f69;
		transition: all 0.4s ease;
	}
}

@keyframes success-flash {
	0% {
		box-shadow: lightgreen 0px 0px 15px 0px;
	}
	50% {
		box-shadow: lightgreen 0px 0px 15px 5px;
	}
	100% {
		box-shadow: lightgreen 0px 0px 15px 0px;
	}
}


@media screen and (min-width: 1200px) {
	.container.c30 {
		/* because tony */
		padding-left: 30px;
		padding-right: 30px;
	}
	.header-half {
		width: 50%;
		&.text {
			padding: 130px;
		}
	}
}

@media screen and (max-width: 1199px) {
	.header-half {
		width: 100%;
		&.text {
			padding: 60px;
		}
	}
}

@media screen and (max-width: 576px) {
	h1, h1.title {
		font-size: 2.5rem;
	}
	.block {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	#ui-datepicker-div {
		font-size: 18px;
	}
	p {
		font-size: 16px;
	}
	p.big {
		font-size: 16px;
	}
	ul {
		font-size: 16px;
		li {
			font-size: 16px;
		}
	}
	.header-half {
		&.text {
			padding: 15px;
		}
		&.video {
			&:after {
				top: 30%;
				top: calc(50% - 35px);
				left: 30%;
				left: calc(50% - 35px);
				height: 70px;
				width: 70px;
			}
		}
	}
}

@import 'header';

/* pages */
@import 'home';
@import 'boxercise-instructor-course';
@import 'boxercise-instructor-course-page';
@import 'search-for-an-instructor-course';
@import '_search-for-a-class-personal-trainer';
@import 'individual-class';
@import '_shop';
@import 'contact';
@import 'contact-instructor';
@import '_instructor-courses-signup';
@import 'instructor-search';
@import 'planner';

/* includes */
@import 'instructor-search-bar';
@import 'instructor-testimonial';
@import 'upcoming-courses-block';
@import '_my-courses-widget';
@import 'footer';

/* fonts */
@import 'fonts';