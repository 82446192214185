#myCoursesWidget {
	position: fixed;
	bottom: -600px;
	z-index: 1000000000000;
	background-color: white;
	border: 5px solid $companyBlue;
	padding: 30px;
	border-radius: 10px;
	.courses {
		overflow-x: hidden;
		overflow-y: hidden;
		height: 29px;
		width: 100%;
		margin-bottom: 15px;
		.course {
			font-size: 12px;
		}
	}
	.close	{
		position: absolute;
		top: 15px;
		right: 15px;
		color: $textGrey;
	}
}

#myCoursesWidget.goAway {
	animation-name: deskTopSlideDown;
	animation-duration: 0.8s;
	animation-fill-mode: forwards;
}

@media (min-width: 720px) {
	#myCoursesWidget {
		min-width: 400px;
	}
}

@media (min-width: 521px) {
	#myCoursesWidget {
		right: 15px;
		min-width: 300px;
	}
	#myCoursesWidget.comeHere {
		animation-name: deskTopSlideUp;
		animation-duration: 0.8s;
		animation-delay: 0.4s;
		animation-fill-mode: forwards;
	}
}

@media (max-width: 520px) {
	#myCoursesWidget {
		bottom: 0;
		right: 0;
		width: 100vw;
	}
}

@keyframes deskTopSlideUp {
	0% {
		bottom: -600px;
	}
	80% {
		bottom: 5px;
	}
	100% {
		bottom: 15px;
	}
}

@keyframes deskTopSlideDown {
	0% {
		bottom: 15px;
	}
	40% {
		bottom: 0px;
	}
	100% {
		bottom: -600px;
	}
}