#courses-signup {
	.newCourse {
		padding: 30px;
		display: flex;
		.count {
			margin-left: -82px;
			margin-right: 30px;
		}
		.info {
			h3 {
				display: inline-block;
			}
			& > span {
				display: inline-block;
				margin-left: 15px;
			}
			.details {
				display: block;
			}
		}
		.actions {
			margin-top: -5px;
			margin-bottom: 10px;
			.delete {
				color: red;
			}
		}
	}
	.yellow-callout {
		background-color: $Yellow;
		padding: 30px;
		text-align: center;
		color: $companyBlue;
		.large {
			font-size: 48px;
			font-family: "SteelFish", sans-serif;
			text-transform: uppercase;
			margin-bottom: 30px;
		}
		p {
			color: $companyBlue;
			margin-bottom: 5px;
		}
		a {
			display: inline-block;
			margin-top: 30px;
		}
	}
	.what-next-title {
		margin-top: 60px;
	}
}
#pathway-diagram {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 100;
	background-color: rgba(50,50,50,0.4);
	display: flex;
	align-items: center;
	justify-content: center;
	.inner {
		padding: 15px;
		position: relative;
	}
	.close {
		position: absolute;
		top: -15px;
		right: -15px;
		background-color: red;
		padding: 10px 15px;
		color: white;
		opacity: 1;
		border-radius: 20px;
	}
	img {
		max-width: 100%;
	}
}

#instructor-checkout {
	.warning-wrapper {
		padding: 30px 15px 15px 15px;
	}
	.input-wrapper {
		padding: 15px;
		label {
			display: block;
			margin: 0;
			padding: 10px 0;
		}
		input, select {
			display: block;
			width: 100%;
			padding: 10px 10px 10px 15px;
			width: 100%;
			width: calc(100% - 35px);
			min-width: 200px;
			border: 1px solid #ccc;
			-webkit-box-shadow: inset 0 0 2px #000;
			box-shadow: inset 0 0 2px #000;
		}
	}
}

@media (max-width: 520px) {
	.book-section .btn {
		width: 100%;
	}
}