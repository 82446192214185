#contact-instructor {
	.contact {
		color: $companyBlue;
		font-weight: 600;
		.top {
			width: 100%;
		}
		.bottom {
			width: 100%;
			a {
				color: $companyBlue;
			}
		}
	}
	#contact-form-holder {
		form {
			label {
				display: inline-block;
				width: 100%;
				color: white;
			}
			input[type="text"],
			input[type="email"],
			input[type="phone"],
			input[type="submit"],
			textarea {
				display: block;
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: 720px) and (min-width: 521px) {
	#contact-instructor {
		.contact {
			.top {
				font-size: 18px;
			}
			.bottom {
				font-size: 24px;
				line-height: 1.25em;
				margin-bottom: 60px;
			}
		}
	}
}

@media screen and (max-width: 984px) and (min-width: 521px) {
	.instructor-profile{
			margin-bottom: 50px;
		}
}

@media screen and (max-width: 576px) {
	#contact-instructor .contact .bottom {
		font-size: 15px;
	}
}

@media screen and (min-width: 521px) {
	#contact-instructor {
		.contact {
			.top {
				font-size: 21px;
			}
			.bottom {
				font-size: 28px;
				line-height: 1.25em;
				margin-bottom: 60px;
			}
		}
		#contact-form-holder {
			padding: 50px;
			form {
				label {
					font-size: 18px;
					margin-bottom: 12px;
				}
				textarea {
					height:200px;
					// height: 80%;
					// height: calc(100% - 154px);
				}
				.g-recaptcha{
					margin-bottom: 15px;
				}
				input, textarea {
					font-size: 18px;
					padding: 30px 15px;
					margin-bottom: 20px;
				}
			}
		}
	}
}

@media screen and (max-width: 520px) {
	#contact-instructor {
		.contact {
			.top {
				font-size: 15px;
			}
			.bottom {
				font-size: 18px;
				line-height: 1.25em;
				margin-bottom: 40px;
			}
		}
		#contact-form-holder {
			padding: 15px;
			margin: -15px;
			margin-bottom: -50px;
			form {
				label {
					font-size: 15px;
					margin-bottom: 12px;
				}
				textarea {
					height:200px;
					// height: 80%;
					// height: calc(100% - 57px);
				}
				.g-recaptcha{
					margin-bottom: 15px;
				}
				input, textarea {
					font-size: 15px;
					padding: 15px;
					margin-bottom: 20px;
				}
			}
		}

		.instructor-profile{
			margin-bottom: 50px;
		}
	}
}

@media screen and (max-width: 515px) {

}