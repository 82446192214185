#home {
	.header {
		min-height: 100vh;
		max-height: 1000px;
		position: relative;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		text-align: center;
		overflow: hidden;
		padding: 60px;
		.content {
			position: relative;
			z-index: 2;
			text-align: center;
			.quote-container {
				max-width: 600px;
				margin: 5% auto;
				hr {
					border-color: #bbb;
					opacity: 0.2;
				}
				.quote {
					color: white;
					font-size: 22px;
				}
				.authour {
					color: $Yellow;
				}
			}
			h1 {
				text-transform: uppercase;
				font-size: 7.5em;
				font-family: 'SteelFish';
				margin-left: 30px;
				margin-right: 30px;
				letter-spacing: 0.05em;
				.yellow, .rest {
					overflow: hidden;
					margin: 0;
					display: inline-block;
					font-weight: normal;
				}
				.yellow {
					color: $Yellow;
					font-family: 'SteelFish';
					white-space: nowrap;
				}
				.rest {
					color: white;
					font-family: 'SteelFish';
				}
			}
			form {
				max-width: 1200px;
				margin-left: auto;
				margin-right: auto;
				a {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					width: 400px;
					padding: 25px 45px;
					border: none;
					font-size: 18px;
				}
				a {
					font-weight: 600;
					&:hover {
						transition: all 0.4s ease;
						cursor: pointer;

					}
				}
				a.BtnInstructor {
					color: $companyBlue;
					background-color: $Yellow;
					border: 2px solid $Yellow;
					&:hover {
						color: $Yellow;
						background-color: transparent;
					}
				}
				a.BtnClass {
					color: white;
					background-color: $companyBlue;
					border: 2px solid $companyBlue;
					&:hover {
						color: white;
						background-color: transparent;
					}
				}
			}
		}
		video {
			position: absolute;
			object-fit: cover;
			top: 50%;
			left: 50%;
			width: auto;
			height: auto;
			z-index: 0;
			min-width: 100%;
			min-height: 100%;
			transform: translate(-50%, -50%);
		}
		.boxerciseposter {
			position: absolute;
			-o-object-fit: cover;
			object-fit: cover;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
		}
		.overlay {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: rgba(0, 0, 0, 0.5);
			z-index: 1;
		}
	}
	.intro {
		text-align: center;
		h2 {
			font-size: 21px;
			font-weight: 600;
			text-transform: uppercase;
			font-family: 'Poppins', sans-serif;
			margin-bottom: 35px;
		}
		h3 {
			text-transform: uppercase;
			font-size: 60px;
			color: $companyBlue;
			font-weight: 400;
			font-family: 'SteelFish', sans-serif;
			margin-bottom: 45px;
		}
		p {
			margin-bottom: 45px;
		}
		a {
			padding-left: 72px;
			padding-right: 72px;
		}
	}
	.shop-banner {
		$shopPadding: 300px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding-top: $shopPadding;
		padding-bottom: $shopPadding;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		transition: all 0.4s ease;
		h3 {
			display: inline-block;
			font-size: 7em;
			color: white;
			font-family: 'SteelFish', sans-serif;
			text-transform: uppercase;
			border: 5px;
			border-color: $Yellow;
			margin-bottom: 0;
			width: fit-content;
			transition: all 0.4s ease;
		}
		&:after {
			transition: all 0.4s ease;
			background-color: transparent;
			content: "";
		}
		&:hover {
			padding-top: 0;
			padding-bottom: 0;
			cursor: pointer;
			text-decoration: none;
			transition: all 0.4s ease;
			background-image: none;
			background-color: $Yellow;
			position: relative;
			h3 {
				z-index: 4;
				transition: all 0.4s ease;
				text-decoration: none;
				padding: $shopPadding 60px;
				width: 100%;
				background-color: transparentize($Yellow, 0.3);
				text-align: center;
				&:hover {
					color: white;
				}
			}
		}
	}
}

@media (max-width: 1400px) and (min-width: 1200px) {
	#home {
		.header {
			.content {
				text-align: center;
				h1 {
					font-size: 6em;
				}
				form {
					width: 980px;
					margin-left: auto;
					margin-right: auto;
					a {
						padding: 30px 20px;
						width: 300px;
					}
				}
			}
		}
	}
}

@media (min-width: 1200px) and (min-height: 750px) {
	#home {
		.header {
			align-items: center;
			.content {
				position: unset;
				.quote-container {
					position: absolute;
					left: 30px;
					bottom: -60px;
				}
			}
		}
	}
}

@media (min-width: 1200px) and (max-height: 750px) {
	#home {
		.header {
			padding: 120px;
		}
	}
}

@media (min-width: 1200px) {
	#home {
		.header {
			margin-top: -138px;
		}
	}
}

@media (max-width: 1199px) {
	#home {
		.header {
			min-height: 80vh;
		}
	}
}

@media (max-width: 1199px) and (min-width: 992px) {
	#home {
		.header {
			.content {
				h1 {
					font-size: 5.5em;
				}
				form {
					width: 960px;
					margin-left: auto;
					margin-right: auto;
					a {
						padding: 30px 20px;
						width: 300px;
					}
				}
			}
		}
	}
}

@media (min-width: 992px) {
	#home {
		.header {
			form {
				display: flex;
				flex-direction: row;
				justify-content: center;
				padding: 20px;
				a {
					padding: 35px 45px;
					display: flex;
					align-items: center;
				}
				a.BtnClass {
					margin-left: 20px;
				}
			}
		}
	}
}

@media (max-width: 991px) and (min-width: 577px) {
	#home {
		.header {
			.content {
				h1 {
					font-size: 80px;
				}
				form {
					margin-left: 30px;
					margin-right: 30px;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					a {
						padding: 30px 20px;
						white-space: nowrap;
					}
					a {
						width: 49%;
						width: calc(50% - 7px);
					}
				}
			}
		}
		.intro {
			h2 {
				margin-bottom: 10px;
			}
			h3 {
				margin-bottom: 40px;
			}
			p {
				margin-bottom: 40px;
			}
		}
		.shop-banner {
			$shopPadding: 135px;
			padding-top: $shopPadding;
			padding-bottom: $shopPadding;
			h3 {
				font-size: 80px;
			}
			&:hover {
				h3 {
					padding: $shopPadding 60px;
				}
			}
		}
	}
}
@media (max-width: 576px) {
	#home {
		.header {
			min-height: calc(95vh - 70px);
			.content {
				h1 {
					font-size: 40px;
				}
				form {
					margin-left: 30px;
					margin-right: 30px;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					a {
						padding: 15px 20px;
						white-space: nowrap;
						font-size: 15px;
					}
					a {
						width: 100%;
						margin-bottom: 15px;
					}
				}
				.quote-container {
					margin-left: 30px;
					margin-right: 30px;
					.quote {
						font-size: 15px;
					}
					.authour {
						font-size: 15px;
					}
				}
			}
		}
		.intro {
			padding-top: 80px;
			padding-bottom: 80px;
			h2 {
				font-size: 12px;
				margin-bottom: 10px;
			}
			h3 {
				font-size: 30px;
				margin-bottom: 40px;
			}
			p {
				margin-bottom: 40px;
				font-size: 15px;
			}
			a {
				padding: 20px 35px;
				font-size: 15px;
			}
		}
		.shop-banner {
			$shopPadding: 150px;
			padding-top: $shopPadding;
			padding-bottom: $shopPadding;
			h3 {
				font-size: 50px;
			}
			&:hover {
				h3 {
					padding: $shopPadding 30px;
				}
			}
		}
	}
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	#home {
		video {
			display: none;
		}
	}
}