nav {
	background-color: $companyBlue;
	position: relative;
	z-index: 100;
	width: 100%;
	ul {
		list-style-type: none;
	}
	a {
		color: white;
		font-family: 'Poppins';
	}
	.courseCartIcon {
		position: relative;
		padding-top: 55px !important;
		.basketNum {
		}
		svg {
			height: 18px;
			width: 18px;
			#Outline_Icons {
				path, rect {
					stroke: white;
				}
			}
		}
	}
	.courseCartIcon:hover svg #Outline_Icons {
		path, rect {
			stroke: $Yellow;
		}
	}
	.coming-soon {
		position: relative;
		&:after {
			content: "Coming Soon";
			position: absolute;
			top: -5px;
			left:30px;
			color: #ff8383;
			font-size: 11px;
		}
	}
}

nav.trnsp {
	background-color: transparent;
}

#profile-picture-container {
	display: block;
	margin: 15px auto;
	border: none;
	background-color: #ccc;
	border-radius: 50%;
	width: 50%;
	padding-top: 50%;
	position: relative;
	#mobile-profile-picture {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 9px;
		width: 100%;
		border-radius: 100px;
	}
}

$desktoppadding: 60px;

@media (min-width: 1200px) {
	nav {
		.nav-container {
			display: flex;
			justify-content: space-between;
		}
		.left {
			margin-left: 100px;
			.logo {
				padding-top: $desktoppadding - 22px;
				padding-bottom: $desktoppadding - 22px;
				img {
					width: 140px;
					transform: scale(1.5);
				}
			}
		}
		.right {
			margin-right: 100px;
			display: flex;
			ul {
				margin: 0;
				padding: 0;
				display: inline-flex;
				li {
					a {
						display: inline-block;
						padding: $desktoppadding 35px;
						line-height: 1em;
						font-weight: 300;
						font-size: 18px;
						&:hover {
							text-decoration: none;
							color: $Yellow;
						}
					}
				}
				.shop-nav-container {
					position: relative;
					.shop-nav {
						position: absolute;
						bottom: 0;
					}
				}
			}
			.options-menu {
				position: relative;
				&:before {
					position: absolute;
					content: "";
					left: 0;
					top: 25%;
					width: 1px;
					height: 50%;
					background-color: rgba(255, 255, 255, 0.3);
				}
			}
		}
	}
}

@media screen and (max-width: 1199px) {
	nav {
		background-color: $companyBlue;
		.nav-container {
			display: flex;
			justify-content: space-between;
		}
		.left {
			.logo {
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					height: 60px;
					margin-left: 30px;
					margin-top: 13px;
				}
			}
		}
		.mobile-menu {
			ul {
				display: inline-flex;
				margin: 0;
				padding: 0;
			}
			.mobilelink {
				padding: 30px;
			}
			.basketIcon, .courseCartIcon {
				position: relative;
				.basketNum {
					position: absolute;
					top: -10px;
					right: -10px;
				}
			}
			.hamburger-container {
				width: 80px;
				padding: 30px;
				.bar {
					display: block;
					height: 2px;
					width: 100%;
					background-color: white;
					margin-top: 6px;
					margin-bottom: 6px;
				}
				&:hover {
					cursor: pointer;
				}
			}
		}
		.slideout-menu {
			background-color: $companyBlue;
			padding-top: 30px;
			a {
				font-size: 18px;
				padding: 15px 5px;
				display: block;
			}
		}
	}
}

@media screen and (min-width: 1200px) {
	nav {
		#menu {
			.opener {
				display: none;
			}
		}
		li.main {
			position: relative;
			&:hover > ul.secondary {
				left: 50%;
				transform: translateX(-50%);
			}
		}
		.right ul.secondary {
			position: absolute;
			top: 90px;
			left: -90000000000000px;
			display: flex;
			flex-direction: column;
			width: unset;
			background-color: $companyBlue;
			li.secondary {
				display: block;
				a {
					display: block;
					padding: 15px 30px;
					white-space: nowrap;
					text-align: left;
				}
			}
		}
	}
}

@media screen and (max-width: 1199px) {
	$menuWith: 80vw;
	nav {
		transition: all 0.4s ease;
		&.trnsp {
			background-color: $companyBlue;
		}
		#menu {
			transition: 0.4s transform ease;
			transform: translate($menuWith);
			position: fixed;
			top: 60px;
			right: 0;
			background-color: $companyBlue;
			padding-top: 20px;
			width: $menuWith;
			height: 100vh;
			overflow-y: scroll;
			text-align: left;
			ul {
				padding: 0;
				margin: 0;
				li a {
					border-bottom: 1px solid white;
					padding: 15px 15px;
					width: 100%;
					display: block;
				}
				li.icon {
					display: none;
				}
			}
			.main {
				position: relative;
				.opener {
					position: absolute;
					top: 10px;
					right: 15px;
					color: white;
					padding: 10px;
					line-height: 1em;
					border: 1px solid white;
					cursor: pointer;
				}
			}
			.courseCartIcon {
				display: none;
			}
			.secondary {
				display: none;
				background-color: rgba(255,255,255,0.2);
				a {
					padding-left: 30px;
					border-bottom: none;
				}
			}
		}
	}
	body {
		#panel {
			transition: transform 0.4s ease;
		}
	}
	body.menuopen {
		overflow: hidden;
		transition: transform 0.4s ease;
		nav {
			#menu {
				transition: transform 0.4s ease;
				transform: translateX(0);
				overflow-y: scroll;
			}
		}
		#panel {
			transition: transform 0.4s ease;
			transform: translateX(-80vw);
		}
	}
}

@media (max-width: 576px) {
	nav {
		.c30 {
			padding-right: 0;
		}
		.nav-container {
			.left {
				.logo {
					img {
						margin-left: 15px;
						height: 33px;
						margin-top: 13px;
					}
				}
			}
			.hamburger-container {
				width: 50px;
				padding: 15px 15px;
				margin-right: 5px;
			}
		}
		.mobile-menu {
			.mobilelink.icon-parent {
				font-size: 24px;
				line-height: 1em;
				padding: 19px 15px 5px 15px;
				a:hover {
					color: $Yellow;
					text-decoration: none;
				}
			}
		}
	}
}