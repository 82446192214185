#boxercise-instructor-course {
	overflow: hidden;
	.header-container {
		padding-top: 30px;
		transition: all 0.4s ease;
		&:hover {
			transition: all 0.4s ease;
			box-shadow: 0px 25px 73px 0px rgba(24, 24, 24, 0.25);
			transform: scale(1.01);
		}
		.subheading {
			color: $companyBlue;
			font-weight: 600;
			text-transform: uppercase;
		}
		h1 {
			margin-bottom: 38px;
		}
		.price {
			color: $companyBlue;
			font-size: 30px;
		}
		a {
			padding-left: 56px;
			padding-right: 56px;
		}
	}
	.extra-course.animateShow {
		transition: all 1.2s linear;
		display: block;
		animation-name: CourseShow;
		animation-duration: 1.2s;
		animation-fill-mode: forwards;
	}
	#drilldown-parent {
		background-color: transparent;
		&:hover {
			background-color: transparent;
			box-shadow: none;
			transform: scale(1);
		}
	}
	#course-drilldown {
		padding: 60px;
		background-color: $companyBlue;
		overflow: hidden;
		position: relative;
		height: 100%;
		p {
			color: white;
			font-size: 18px;
			margin: 30px 0;
		}
		select {
			width: 100%;
			padding: 30px;
		}
		.special-offer {
			position: absolute;
			left: 0;
			bottom: 0;
			padding: 60px;
			z-index: 2;
			text-transform: uppercase;
			font-size: 18px;
			& > * {
				margin :0;
			}
			.yellow {
				color: $Yellow;
				font-family: "Poppins", sans-serif;
				font-weight: 700;
			}
			.book {
				color: white;
				font-size: 36px;
				font-family: 'SteelFish', sans-serif;
			}
			.subheading {
				color: white;
				font-family: 'SteelFish', sans-serif;
				letter-spacing: 0.1em;
			}
		}
		&:after {
			content: "";
			position: absolute;
			bottom: -35%;
			right: 0;
			width: 130%;
			padding-top: 100%;
			background-color: #293d82;
			border-radius: 50%;
		}
	}
	#inhouse-ad-block {
		background-color: #182654;
		padding: 0;
		.subheading {
			color: $Yellow;
			font-weight: 600;
		}
		.title {
			color: white;
			font-family: 'SteelFish', sans-serif;
			font-weight: 400;
			text-transform: uppercase;
		}
		p {
			color: white;
		}
		a {
			display: inline-block;
			color: $companyBlue;
			font-weight: 600;
			&:hover {
				color: $Yellow;
			}
		}
	}
}
.courses {
	.course, .special-course {
		display: block;
		margin-top: 30px;
		margin-bottom: 30px;
		transition: all 0.4s ease;
		&:hover {
			box-shadow: 0px 25px 73px 0px rgba(24, 24, 24, 0.25);
			transform: scale(1.01);
		}
	}
	.course {
		background-color: white;
		.top-img {
			height: 275px;
			width:100%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}
		.bottom-text {
			display: flex;
			flex-direction: column;
			.subheading {
				color: $companyBlue;
				text-transform: uppercase;
				font-weight: 600;
			}
			h3 {
				text-transform: uppercase;
				color: $companyBlue;
				font-family: 'SteelFish', sans-serif;
				font-size: 36px;
				font-weight: 400;
				margin-bottom: 16px;
			}
			.price {
				font-size: 24px;
				color: $companyBlue;
				font-weight: 700;
				margin-top: auto;
				margin-bottom: 0;
			}

		}
		&:hover {
			text-decoration: none;
		}
	}
}

@keyframes CourseShow {
	0% {
		opacity: 0;
		transform:rotate3d(0,1,0,90deg);
	}
	10% {
		opacity: 1;
	}
	100% {
		transform:rotate3d(0);
	}
}

@media screen and (min-width: 1611px) {
	.courses {
		.course {
			.bottom-text {
				height: 375px;
			}
		}
	}
}

@media screen and (max-width: 1610px) and (min-width: 1200px) {
	.courses {
		.course {
			.bottom-text {
				height: 500px;
			}
		}
	}
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
	#boxercise-instructor-course {
		.header-half:first-child {
			height: 400px;
		}
	}
	.courses {
		.course {
			.bottom-text {
				height: 370px;
			}
		}
	}
}


@media screen and (min-width: 992px) {
		.courses {
			.course {
				.bottom-text {
					padding: 60px;
				}
			}
			.special-course {
				padding: 60px;
			}
		}
}

@media screen and (max-width: 991px) {
	#boxercise-instructor-course {
		.header-half:first-child {
			height: 275px;
		}
		#inhouse-ad-block {
			.header-half {
				a.yellowButton {
					font-size: 15px;
					display: block;
					padding: 25px;
				}
			}
		}
	}
	.courses {
		.course {
			.bottom-text {
				padding: 30px;
			}
		}
		.special-course {
			padding: 30px;
		}
	}
}

@media screen and (max-width: 847px) {
	#boxercise-instructor-course {
		#upcoming-courses-block {
			#upcomingcourse {
				li a {
					.course {
						padding-left: 60px;
						padding-top: 10px;
						width: 100%;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 577px) {
	#boxercise-instructor-course {
		#inhouse-ad-block {
			.subheading {
				margin-bottom: 8px;
			}
			.title {
				font-size: 60px;
				margin-bottom: 40px;
			}
			p {
				color: white;
			}
			a {
				margin-top: 44px;
				display: inline-block;
				color: $companyBlue;
				font-weight: 600;
				&:hover {
					color: $Yellow;
				}
			}
		}
	}
}

@media screen and (max-width: 576px) {
	#boxercise-instructor-course {
		.header-container {
			padding-top: 30px;
			.header-half:first-child {
				height: 180px;
			}
			.subheading {
				font-size: 18px;
			}
			h1 {
				font-size: 36px;
				font-weight: 400;
			}
			p.price {
				font-size: 24px;
    			color: #1e2f69;
    			font-weight: 700;
			} 
		}
	}
	.courses {
		.course {
			.top-img {
				height: 180px;
			}
		}
	}
}