.instructor-price-callout {
	font-size: 12px;
}

nav {
	.basketIcon {
		position: relative;
	}
	.basketNum {
		position: absolute;
		top: 50px;
		right: 20px;
		background-color: rgba(239, 46, 46, 0.8);
		padding: 5px 8px;
		line-height: 1em;
		border-radius: 20px;
		font-size: 12px;
	}
}

#shop-header {
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		li {
			a {
				color: $companyBlue;
				&:hover {
					text-decoration: none;
					color: white;
				}
			}
		}
	}
}

#shop-home {
	.header-container {
		.blueYellowButton {
			font-weight: bold;
			padding-left: 77px;
			padding-right: 77px;
		}
	}
	h2 {
		text-transform: uppercase;
	}
	.featured-products {
		h1 {
			text-transform: uppercase;
		}
	}
	.product {
		display: block;
		margin-top: 30px;
		margin-bottom: 30px;
		transition: all 0.4s ease;
		background-color: white;
		padding: 30px;
		&:hover {
			box-shadow: 0px 25px 73px 0px rgba(24, 24, 24, 0.25);
			transform: scale(1.01);
		}
		.top-img {
			height: 275px;
			width:100%;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			position: relative;
			&.sale:after {
				content: "";
				width: 130px;
				height: 130px;
				background-image: url("../assets/shop/sale.png");
				background-size: contain;
				background-repeat: no-repeat;
				position: absolute;
				top: -15px;
				left: -15px;
			}
			&.exclusive:before {
				content: "";
				width: 130px;
				height: 130px;
				background-image: url("../assets/shop/exclusive.png");
				background-size: contain;
				background-repeat: no-repeat;
				position: absolute;
				top: -15px;
				left: -15px;
				z-index: 2;
			}
			&.exclusive.sale:before {
				left: 30%;
			}
		}
		.bottom-text {
			margin-top: 15px;
			h3 {
				color: $companyBlue;
				font-size: 23px;
				line-height: 34px;
				font-weight: 600;
				margin-bottom: 16px;
				overflow: hidden;
				width: 100%;
			}
			.price {
				margin: 0;
				font-size: 24px;
				color: $companyBlue;
				font-weight: 400;
				.price-before {
					text-decoration: line-through;
				}
				.price-after {
					color: #be0624;
					position: relative;
				}
				.instructor-price-callout {
					position: absolute;
					left: 0;
					top: -5px;
					font-size: 12px;
					line-height: 1em;
					white-space: nowrap;
				}
			}
		}
		&:hover {
			text-decoration: none;
		}
	}
}

#shop-product {
	.top-content {
		margin-top: 60px;
		margin-bottom: 40px;
	}
	.video-container {
		margin: 40px 100px;
	}
	.breadcrumbs {
		list-style-type: none;
		padding: 0;
		margin: 0;
		li {
			display: inline-block;
			color: $companyBlue;
			font-size: 15px;
			padding: 5px 12px;
			position: relative;
			&:first-child {
				padding-left: 0;
			}
			&:not(:first-child):before {
				content: ">";
				position: absolute;
				bottom: 4px;
				left: -6px;
				color: $textGrey;
			}
			a {
				color: $textGrey;
			}
		}
	}
	.main-content {
		margin-bottom: 130px;
		.top-text {
			h1 {
				color: $companyBlue;
				line-height: 1.5em;
				font-family: 'Poppins', sans-serif;
				font-weight: 600;
			}
			.price {
				.price-before {
					text-decoration: line-through;
				}
				.price-after {
					color: #be0624;
					padding-left: 15px;
				}
			}
		}
		.variation-tooltip {
			color: $textGrey;
			margin-left: 15px;
			margin-top: 30px;
		}
		.variation-container-row {
			padding: 15px 0;
		}
		.variation-container {
			position: relative;
			display: inline-block;
			margin: 15px;
			cursor: pointer;
			input {
				z-index:3;
				opacity: 0;
			}
			span {
				display: inline-block;
				z-index: 1;
				color: $companyBlue;
				background-color: white;
				padding: 30px;
				border: 1px solid #7a7a7a;
			}
			input {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}
			input:checked ~ span {
				background-color: $companyBlue;
				color: white;
			}
		}
		#quantity {
			display: inline-block;
			width: 100%;
		}
		.add-basket {
			display: block;
			background-color: $companyBlue;
			color: white;
			font-weight: 600;
			text-align: center;
			transition: all 0.4s ease;
			border: 2px solid $companyBlue;
			margin-top: 30px;
			width: 100%;
			&:hover {
				color: $companyBlue;
				background-color: transparent;
				text-decoration: none;
				cursor: pointer;
			}
		}
	}
	.slider-parent {
	}
	.main-slider {
		overflow: hidden;
		margin: 0px 80px;
	}
	.slick-slide {
		height: 450px;
		.magnify {
			width: 100%;
			height: 100%;
		}
		img {
			height: 450px;
			width: 100%;
			object-fit: contain;
			object-position: center;
		}
	}
	.secondary-slider-container {
		position: relative;
		#secondaryPrev {
			position: absolute;
			left: 7%;
			top: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			transform: translateY(-50%);
			width: 60px;
			height: 60px;
			color: white;
			font-weight: bold;
			font-size: 48px;
			background-color: $companyBlue;
			cursor: pointer;
			-moz-user-select: none; /* Firefox */
			-ms-user-select: none; /* Internet Explorer */
			-khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
			-webkit-user-select: none; /* Chrome, Safari, and Opera */
			-webkit-touch-callout: none; /* Disable Android and iOS callouts*/
		}
		#secondaryNext {
			position: absolute;
			right: 7%;
			top: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			transform: translateY(-50%);
			width: 60px;
			height: 60px;
			color: white;
			font-weight: bold;
			font-size: 48px;
			background-color: $companyBlue;
			cursor: pointer;
			-moz-user-select: none; /* Firefox */
			-ms-user-select: none; /* Internet Explorer */
			-khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
			-webkit-user-select: none; /* Chrome, Safari, and Opera */
			-webkit-touch-callout: none; /* Disable Android and iOS callouts*/
		}
	}
	.secondary-slider {
		border: 1px solid #dddddd;
		padding: 10px;
		margin-left: 15%;
		margin-right: 15%;
		margin-top: 50px;
		overflow: hidden;
	}
	.secondary-slider .secondaryImg {
		display: inline-block;
		margin-left: 15px;
		margin-right: 15px;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		cursor: pointer;
	}
}

#newProduct {
	.newProductImage {
		width: 100%;
	}
	.details {
		h2 {
			margin-top: 15px;
		}
		p {
			margin-top: 5px;
			margin-bottom: 5px;
		}
	}
}

#shop-cart {
	.cart-items {
		border-top: 1px solid #ccc;
		padding: 30px;
		padding-top: 0;
		.item {
			border-bottom: 1px solid #ccc;
			padding-bottom: 30px;
			padding-top: 30px;
			h2 {
				font-family: "Poppins", sans-serif;
				letter-spacing: unset;
				font-size: 21px;
			}
			p {
				margin-top: 5px;
				margin-bottom: 5px;
			}
			p.product {
				margin: 0;
				color: $companyBlue;
				.price-before {
					text-decoration: line-through;
				}
				.price-after {
					color: #be0624;
				}
			}
			input[type="number"] {
				color: #6a6a6a;
				border: 1px solid #ccc;
				width: 100%;
				max-width: 100px;
				padding: 1px 5px 1px 15px;
			}
			.view,
			.delete {
				cursor: pointer;
				&:hover {
					text-decoration: underline;
				}
			}
			.view {
				color: blue;
			}
			.delete {
				color: red;
			}
		}
	}
	.summary {
		border: 1px solid #ccc;
		padding: 30px;
	}
}

.discount-tick,
.discount-cross {
	margin-top: 5px;
}

.discount-tick {
	color: green;
	font-weight: bold;
}
.discount-cross {
	color: red;
	font-weight: bold;
}

.subscribeBar {
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	h3 {
		color: white;
		margin-bottom: 40px;
	}
	form {
		width: 100%;
		max-width: 650px;
		display: flex;
		margin-left: 15px;
		margin-right:15px;
		input[type="email"] {
			flex: 1;
			padding-left: 30px;
			margin: 0;
			border: none;
		}
		input[type="submit"] {
			width: 80px;
			height: 80px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 24px;
			font-weight: 400;
			padding: 0;
			line-height: 1em;
		}
	}
}


@media screen and (max-width: 544px) {
	#shop-home {
		.product-holder {
			.product {
				margin-top: 10px;
				margin-bottom: 10px;
				padding: 15px;
				.top-img {
					display: inline-block;
					height:150px;
					width: 45%;
					&:after {
						width: 85px;
						height: 85px;
						top: -25px;
						left: -25px;
					}
					&:before {
						width: 85px;
						height: 85px;
						top: -25px;
					}
				}
				.bottom-text {
					display: inline-block;
					margin-top: 15px;
					width: 50%;
					float: right;
					h3 {
						font-size: 18px;
						line-height: 30px;
					}
					.price {
						font-size: 18px;
					}
				}
			}
		}
	}
	.subscribeBar {
		padding-left: 30px;
		padding-right: 30px;
		h3 {
			font-size: 18px;
		}
		form {
			display: block;
			input[type="email"] {
				width: 85%;
				width: calc(100% - 30px);
				margin-left: 15px;
				margin-right: 15px;
				padding-top: 20px;
				padding-bottom: 20px;
			}
			input[type="submit"] {
				height: 64px;
				width: 85%;
				width: calc(100% - 30px);
				margin-left: 15px;
				margin-right: 15px;
				font-size: 16px;
				text-align: left;
				padding-left: 30px;
				margin-top:5px;
			}
		}	
	}
	#shop-product {
		.main-slider {
			margin: 0 15px;
		}
		.secondary-slider {
			margin-left: 0;
			margin-right: 0;
			padding: 15px 0;
			min-height: 80px;
		}
	}
}
@media screen and (min-width: 576px) {
	#shop-home .product .bottom-text h3 {
		height: 98px;
	}
}

@media screen and (min-width: 791px) {
	#shop-product {
		.main-content {
			.top-text {
				h1 {
					font-size: 24px;
					margin-bottom: 20px;
				}
				.price {
					font-size: 24px;
					margin-bottom: 40px;
				}
				p {
					margin-bottom: 50px;
				}
			}
			#quantity {
				margin-top: 30px;
				padding:30px 30px;
			}
			.add-basket {
				margin-bottom: 50px;
				padding-top: 30px;
				padding-bottom: 30px;
			}
		}
	}
	.info-title {
		padding-top: 30px;
		padding-bottom: 30px;
		font-size: 18px;
	}
	.info-desc {
		padding: 30px 15px;
	}
}

@media screen and (max-width: 790px) {
	#shop-product {
		h1 {
			font-size: 21px;
			margin-top: 50px;
		}
		.price {
			font-size: 21px;
		}
		.main-content {
			.top-text {
				font-size: 18px;
			}
			.add-basket {
				margin: 15px;
				margin-left: 0;
				padding-top: 15px;
				padding-bottom: 15px;
			}
			#quantity {
				margin: 15px;
				margin-left: 0;
				padding: 15px;
			}
		}
	}
	.info-title {
		font-size: 18px;
		padding: 15px;
	}
	.info-desc {
		padding: 5px 15px;
	}
}

@media screen and (max-width: 991px) {
	#shop-header {
		margin:30px;
		.container {
			padding: 0;
			width: 100%;
			.title {
				padding-top: 15px;
			    padding-bottom: 15px;
			    text-align: center;
			    cursor: pointer;
			    .arrow {
			    	display: inline-block;
			    }
			    &.open .arrow {
					transform: rotate(180deg);
			    }
			}
			ul {
			    display: none;
			    background-color: white;
			    border: 2px solid #f5e22a;
			    li {
				    border: 1px solid #eee;
				    a {
				    	display: inline-block;
				    	padding: 15px;
				    	width: 100%;
				    	transition: 0.4s ease all;
				    	&:hover {
				    		background-color: $companyBlue;
				    	}
				    }
			    }

			}
		}
	}
}

@media screen and (max-width: 991px) and (min-width: 521px) {
	#shop-header {
		.container {
			ul {
			    li {
				    width: 50%;
				    display: inline-block;
				    float: left;
				    &:last-child:after {
				    	content: "";
				    	clear:both;
				    }
			    }
			}
		}
	}
}

@media screen and (min-width: 992px) {
	#shop-header {
		.clear {
			display: none;
		}
	}
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
	#shop-header {
		.title {
			display: none;
		}
		ul {
			width: 100%;
			display: flex;
			justify-content: left;
			flex-wrap: wrap;
			li {
				display: inline-block;
				padding: 33px 30px;
				line-height: 1em;
				text-transform: capitalize;
				font-size: 18px;
			}
		}
	}
	#shop-home {
		.featured-products {
			h1 {
				font-size: 40px;
				margin-bottom: 40px;
			}
		}
		.product {
			.bottom-text {
				h3 {
					font-size: 18px;
					line-height: 1.2em;
				}
			}
		}
	}
}


@media screen and (min-width: 1200px) {
	#shop-header {
		.title {
			display: none;
		}
		ul {
			width: 100%;
			display: flex;
			justify-content: space-between;
			li {
				display: inline-block;
				padding: 33px 5px;
				line-height: 1em;
				text-transform: capitalize;
				font-size: 18px;
			}
		}
	}
	#shop-home {
		.header-container {
			min-height: 700px;
			.text {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: baseline;
			}
			h2 {
				font-size: 60px;
				color: white;
				margin-bottom: 25px;
			}
			p {
				color: white;
				margin-bottom: 25px;
			}
		}
		.featured-products {
			h1 {
				font-size: 60px;
				margin-bottom: 100px;
			}
		}
	}
}