#upcoming-courses-block {
	position: relative;
	overflow: hidden;
	background-color: #1e2f69;
	#upcomingCourse-map {
		width: 60vw;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 2;
	}
	li {
		position: relative;
		z-index: 4;
	}
	.upcomingcourse {
		transition: all 0.4s ease;
	}
	.yellow {
		.upcomingcourse {
			transition: 0.4s all ease;
			background-color: rgba($Yellow, 0.5);
			transform: scale(1.02);
			.course, .location, .icon {
				color: white !important;
			}
		}
	}
	.container {
		position: relative;
	}
	h2 {
		text-transform: uppercase;
		color: white;
	}
	#upcomingcourse {
		list-style-type: none;
		padding: 0;
		margin: 0;
		li {
			border-top: 1px solid rgba(204, 204, 204, 0.12);
			a {
				&:hover {
					text-decoration: none;
				}
			}
		}
		li:first-child {
			border-top: none;
		}
		.icon, .location {
			color: #727fad;
		}
		.date {
			color: white;
		}
		.course {
			color: $Yellow;
		}
		.book {
			padding: 10px 15px;
			margin-top: 5px;
			background-color: $Yellow;
			color: $companyBlue;
			border: 1px solid $Yellow;
			transition: 0.4s ease all;
		}
		.course:hover {
			.book {
				display: inline-block;
				margin-left: 20px;
				background-color: transparent;
				color: $Yellow;
			}
		}
	}
	.yearPlanner {
		background-color: $Yellow;
		color: $companyBlue;
		font-weight: 600;
		border: 2px solid $Yellow;
		transition: all ease 0.4s;
		&:hover {
			transition: all ease 0.4s;
			color: $Yellow;
			background-color: transparent;
			text-decoration: none;
		}
	}
	&.minified {
		padding: 30px;
		h3 {
			color: white;
			text-align: center;
		}
		span.course {
			display: block;
			margin-top: 10px;
			margin-left: 65px;
		}
		.book {
			margin-left: 65px;
		}
	}
}

@media screen and (max-width: 577px) {
	#upcoming-courses-block.minified {
		display: none;
	}
}

@media screen and (max-width: 783px) and (min-width: 577px) {
	#upcoming-courses-block {
		#upcomingcourse {
			.book, .course {
				margin-left: 65px;
			}
		}
	}
}

@media screen and (max-width: 991px) and (min-width: 577px) {
	#upcoming-courses-block {
		#upcomingcourse {
			.course {
				margin-top: 10px;
				margin-left: 65px;
			}
		}
	}
}

@media screen and (max-width: 847px) and (min-width: 577px) {
	.yearPlanner {
		margin-left: 60px;
	}
	#upcomingCourse-map {
		display: none;
	}
}

@media screen and (min-width: 577px) {
	#upcoming-courses-block {
		display: flex;
		justify-content: center;
		.special-container {
			width: 100%;
			max-width: 1500px;
			margin-left: 30px;
			margin-right: 30px;
		}
		h2 {
			font-size: 60px;
			margin-bottom: 30px;
		}
		#upcomingcourse {
			margin-top: 20px;
			margin-bottom: 20px;
			width: 100%;
			li {
				display: inline-block;
				a {
					display: block;
					font-size: 21px;
					line-height: 1em;
					padding: 20px 5px;
					span {
						text-align: left;
						display: inline-block;
					}
					.icon {
						width: 60px;
					}
					.date {
						width: 150px;
					}
					.location {
						width: 250px;
					}
					.course {
						width: 417px;
					}
				}
			}
		}
		.yearPlanner {
			display: inline-block;
			padding: 35px 45px;
			font-size: 18px;
		}
		&.minified {
			#upcomingcourse {
				li {
					a {
						padding: 10px 5px;
						&.course {
							margin-top: 15px;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 576px) {
	#upcomingCourse-map {
		display: none;
	}
	#upcoming-courses-block {
		display: flex;
		justify-content: center;
		.special-container {
			width: 100%;
			max-width: 1500px;
			margin-left: 30px;
			margin-right: 30px;
		}
		h2 {
			font-size: 2.5rem;
			margin-bottom: 30px;
			margin-left: 0px;
		}
		#upcomingcourse {
			margin-top: 20px;
			margin-bottom: 20px;
			width: 100%;
			li {
				display: inline-block;
				a {
					display: block;
					font-size: 15px;
					line-height: 1em;
					padding: 20px 5px;
					span {
						text-align: left;
						display: inline-block;
					}
					.icon {
						width: 60px;
						text-align: center;
					}
					.date {
						width: 60px;
					}
					.location {
						width: auto;
					}
					.course {
						width: 100%;
						padding-left: 60px;
						padding-top: 10px;
					}
					.book {
						margin-left: 60px;
					}
				}
			}
		}
		.yearPlanner {
			display: inline-block;
			padding: 25px 15px;
			font-size: 15px;
			width: 100%;
			max-width: 500px;
			text-align: center;
		}
	}
}