#planner {
	h1 {
		margin-top: 15px;
	}
	table {
		font-size: 11px;
	}
	.yearrow {
		background: #00306a;
		min-height: 50px;
		color: white;
		position: sticky;
		top: 0;
		z-index: 100;
	}
	.yearrow td {
		background: #00306a;
		border: 1px solid black;
	}
	.weeksrow {
		z-index: 101;
		height: 50px;
	}
	td {
		position: relative;
		min-height: 15px;
	}
	.greyedblock {
		display: block;
		position: absolute;
		top: 2px;
		left: 2px;
		width: calc(100% - 4px);
		height: calc(100% - 4px);
		background-color: #ccc;
	}
	table td {
		vertical-align: text-top;
		border: 1px solid #ccc;

	}
	.goodblock {
		position: relative;
		display: block;
		min-height: 50px;
	}
	.goodblock .number {
		display: block;
		padding: 5px;
		color: black;
	}
	.goodblock a {
		display: block;
		padding: 5px;
		color: black;
	}
	.course-key a {
		display: inline-block;
		margin: 5px 15px;
		padding: 5px;
		color: black;
	}
	.course-key a:hover {
		text-decoration: none;
		color: white;
	}
	.course-btn:hover {
		text-decoration: none;
		color: white;
	}
	.course-1 {
		background-color: #9cf;
	}
	.course-2 {
		background-color: pink;
	}
	.course-3 {
		background-color: #96F;
	}
	.course-4 {
		background-color: #f63;
	}
	.course-5 {
		background-color: #9fc;
	}
	/* Tooltip container */
	.course-tooltip {
		position: relative;
		display: block;
		color:red;
	}

	/* Tooltip text */
	.course-tooltip .course-tooltiptext {
		visibility: hidden;
		width: 120px;
		background-color: black;
		color: #fff;
		text-align: center;
		padding: 5px 0;
		border-radius: 6px;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);

		/* Position the tooltip text - see examples below! */
		position: absolute;
		z-index: 1;
	}

	/* Show the tooltip text when you mouse over the tooltip container */
	.course-tooltip:hover .course-tooltiptext {
		visibility: visible;
	}
}