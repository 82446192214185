#individual-class {
	.info-block {
		.instructor {
			color: white;
			font-family: "SteelFish", sans-serif;
			font-weight: 600;
			text-transform: uppercase;
		}
		.name {
			font-weight: 400;
			color: white;
			text-transform: uppercase;
		}
		.location {
			color: $textBabyPurple;
		}
		.map-link {
			color: $textBabyPurple;
		}
		.dates {
			ul {
				padding: 0;
				margin: 0;
				list-style-type: none;
				li {
					width: 100%;
					span {
						color: white;
						display: inline-block;
					}
				}
			}
		}
		.price {
			color: white;
			.lead {
				font-family: 'SteelFish', sans-serif;
				font-weight: 600;
			}
		}
	}
	.contact-info {
		border-top: 1px solid #d3d3d3;
		list-style-type: none;
		margin: 0;
		padding: 0;
		li {
			position: relative;
			border-bottom: 1px solid #d3d3d3;
			font-weight: 600;
			a {
				display: inline-block;
				color: $companyBlue;
				width: 100%;
			}
			&:after {
				position: absolute;
				font-family: 'Boxercise';
				color: $companyBlue;
			}
			&.phone:after {
				content: "p";
			}
			&.email:after {
				content: "e";
			}
		}
	}
	#contactBtn {
		color: $companyBlue;
		width: 100%;
		font-weight: 600;
		&:hover {
			color: $Yellow;
		}
	}
}

@media screen and (min-width: 992px) {
	#individual-class {
		.info-block {
			padding: 100px;
			.instructor {
				font-size: 21px;
				margin-bottom: 5px;
				letter-spacing: 0.05em;
			}
			.name {
				font-size: 60px;
				margin-bottom: 30px;
			}
			.location, .map-link, .dates {
				font-size: 21px;
				margin-bottom: 16px;
				display: block;
			}
			.dotw {
				width: 120px;
			}
			.price {
				.lead {
					font-size: 30px;
					letter-spacing: 0.05em;
				}
				.extra {
					font-size: 21px;
				}
			}
		}
		.contact-info {
			margin-top: 40px;
			margin-bottom: 40px;
			li {
				padding-left: 50px;
				padding-top: 30px;
				padding-bottom: 30px;
				font-size: 18px;
				a {
					font-size: 18px;
				}
				&:after {
					top: calc(50% - 14px);
					left: 10px;
				}
			}
		}
	}
}

@media screen and (max-width: 991px) and (min-width: 577px) {
	#individual-class {
		.info-block {
			padding: 80px;
			.instructor {
				font-size: 18px;
				margin-bottom: 5px;
				letter-spacing: 0.05em;
			}
			.name {
				margin-bottom: 30px;
			}
			.location, .map-link, .dates {
				font-size: 18px;
				margin-bottom: 16px;
				display: block;
			}
			.dotw {
				width: 120px;
			}
			.price {
				.lead {
					font-size: 30px;
					letter-spacing: 0.05em;
				}
				.extra {
					font-size: 18px;
				}
			}
		}
		.contact-info {
			margin-top: 40px;
			margin-bottom: 40px;
			li {
				padding-left: 50px;
				padding-top: 30px;
				padding-bottom: 30px;
				font-size: 18px;
				a {
					font-size: 18px;
				}
				&:after {
					top: calc(50% - 14px);
					left: 10px;
				}
			}
		}
		.details {
			margin-top: 40px;
			p {
				font-size: 18px;
			}
		}
	}
}

@media screen and (max-width: 576px) {
	#individual-class {
		.info-block {
			padding: 50px;
			.instructor {
				font-size: 18px;
				margin-bottom: 5px;
				letter-spacing: 0.05em;
			}
			.name {
				margin-bottom: 8px;
			}
			.location, .map-link, .dates {
				font-size: 15px;
				margin-bottom: 8px;
				display: block;
			}
			.dotw {
				width: 90px;
			}
			.price {
				.lead {
					font-size: 30px;
					letter-spacing: 0.05em;
				}
				.extra {
					font-size: 15px;
				}
			}
		}
		.contact-info {
			margin-top: 50px;
			margin-bottom: 30px;
			li {
				padding-left: 30px;
				padding-top: 15px;
				padding-bottom: 15px;
				font-size: 15px;
				a {
					font-size: 15px;
				}
				&:after {
					top: calc(50% - 10px);
					left: 10px;
				}
			}
		}
		#contactBtn {
			font-size: 15px;
			padding-top: 15px;
			padding-bottom: 15px;
		}
		.details {
			margin-top: 30px;
			p {
				font-size: 15px;
			}
		}
	}
}