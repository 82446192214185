#instructor-search-results {
	img {
		width: 100%;
	}
	.main {
		font-size: 21px;
		color: $textGrey;
		.name {
			font-size: 36px;
			text-transform: uppercase;
			display: inline-block;
		}
		.verified {
			display: inline-block;
			color: darkgreen;
			font-weight: bold;
			margin-bottom: 30px;
		}
		.desc {
			display: block;
			width: 100%;
			font-weight: bold;
			font-size: 15px;
			margin-top: 10px;
			margin-bottom: 0;
		}
	}
	.result {
		margin-top: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid #ccc;
		padding-top: 30px;
		padding-bottom: 30px;
		width: 100%;
		.photo-holder{
			position: relative;
			.gymcatch-logo {
				position: absolute;
				top: -30px;
				right: -30px;
				width: 100px;
				height: 100px;
			}
		}
		img {
			max-height: 300px;
		}
		.bottom {
			font-weight: 500;
		}
		.desc {
			font-weight: 400;
			color: $textGrey;
		}
		.top {
			font-size: 21px;
			margin-bottom: 10px;
		}
		.name {
			display: inline-block;
			margin-right: 20px;
		}


		.email-instructor{
			display:inline-block;
			margin-bottom:15px;
			color: #00306a;
		}
	}
}

.class-booking-facility {
	margin: 60px 0;
}

.location-flex {
	max-width: 100vw;
	overflow-x: hidden;
}

@media screen and (max-width: 576px) {
	#instructor-search-results {
		img {
			max-height:200px;
		}
		.main {
			font-size: 15px;
			img {
				object-fit: contain;
				object-position: left;
				margin-bottom: 30px;
			}
		}
		.top-col {
			display: flex;
			align-items: center;
		}
		.result {
			margin-left: 0;
			margin-right: 0;
			.top {
				font-size: 21px;
			}
			font-size: 15px;
		}
		.bottom {
			margin-top: 15px;
			margin-left: 15px;
		}
	}
}

@media(min-height: 801px) {
	.floating-submit {
		display: none;
	}
}

@media (max-height: 800px) {
	#instructor-search-bar.mini {
		height: 0;
		padding: 0;
		overflow: hidden;
	}
	.floating-submit {
		display: block !important;
		position: fixed;
		bottom: 30px;
		right: 30px;
		width: 100px;
		height: 100px;
		font-size: 28px;
		font-weight: bold;
		border-radius: 100px;
		z-index: 100;
		-webkit-transition: all .4s;
		transition: all .4s;
		background-color: #f5e22a;
		padding-top: 30px;
		padding-bottom: 30px;
		text-align: center;
		color: #00306a;
		border: 2px solid #f5e22a;
		margin-bottom: 5px;
		border-radius: 100px;
	}
}