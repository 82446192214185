#instructor-search-bar {
	.greyBlock & {
		h1, a {
			color: $companyBlue;
		}

	}
	.blueBlock & {
		h1 {
			color: white;
		}
		.yellowLink {
			color: $Yellow;
		}
	}
}

#instructor-search-bar {
	width: 100%;
	text-align: center;
	& * {
		max-width: 100%;
	}
	a.btn {
		margin: 5px;
	}
	h1 {
		font-family: 'SteelFish', sans-serif;
		font-weight: 400;
		margin-bottom: 16px;
		text-transform: uppercase;
	}
	p {
		color: $textLightGrey;
		a {
			color: $companyBlue;
		}
	}
	form > span {
		display: block;
	}
	input, select, .datepicker {
		position: relative;
	}
	input, select, .datepicker input {
		border: 1px solid #ddd;
	}
	select {
		color: #696969;
		background-color: white;
		border: 1px solid #ddd;
	}
	input[type="submit"] {
		@include button($companyBlue, $Yellow);
		&:hover {
			cursor: pointer;
		}
	}
	#Input-location-container {
		position: relative;
		&:after {
			position: absolute;
			top: 0;
			top: calc(50% - 16px);
			left: 15px;
			width: 20px;
			content: "l";
			font-family: 'Boxercise';
			color: $companyBlue;
		}
	}
	#Inpt-fromDate-container, #Inpt-toDate-container {
		position: relative;
		&:after {
			position: absolute;
			top: 20px;
			left: 15px;
			width: 20px;
			content: "c";
			font-family: 'Boxercise';
			color: $companyBlue;
		}
	}
}

@media screen and (min-width: 992px) {
	#instructor-search-bar {
		padding-top: 80px;
		padding-bottom: 80px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.hidden-md-up {
			display: none !important;
		}
		h1 {
			font-size: 60px;
			margin-bottom: 30px;
		}
		p {
			font-size: 21px;
			margin-bottom: 30px;
		}
		form {
			display: flex;
			width: 100%;
			max-width: 1500px;
			padding-left: 15px;
			padding-right: 15px;
			& > select, & > input, & > span, .datepicker {
				flex: 1;
				margin-left: 12px;
				margin-right: 12px;
			}
			select, input, span {
				height: 80px;
				border: none;
			}
			select, input {
				padding-left: 45px;
				border: 1px solid #ccc;
			}
			#Inpt-location {
				width: 100%;
			}
			.datepicker {
				display: flex;
				span {
					flex: 1;
					input {
						width: 100%;
						border-left: none;
					}
				}
				span:first-child {
					input {
						border-left: 1px solid #ccc;
					}
				}
			}
			input[type="submit"] {
				flex: unset;
				width: 80px;
				padding: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: bold;
				font-size: 25px;
			}
			#Input-location-container:after ,#Inpt-fromDate-container:after, #Inpt-toDate-container:after {
				top: 28px;
				width: 30px;
			}
		}
	}
}

@media screen and (max-width: 991px) {
	#instructor-search-bar {
		padding: 40px 30px;
		.hidden-sm-down {
			display: none !important;
		}
	}
}

@media screen and (max-width: 991px) and (min-width: 577px) {
	#instructor-search-bar {
		input, select, input[type="submit"] {
			padding: 15px 35px;
			margin-top: 7px;
			margin-bottom: 7px;
			font-size: 15px;
			border: 1px solid #ddd;
		}
		input, input[type="submit"] {
			width: 100%;
		}
		select {
			width: 50%;
			border: 1px solid #ddd;
			float: left;
		}
		#Input-location-container {
			&:after, &::after {
				top: 20px;
			}
		}
		.datepicker {
			width: 100%;
			display: flex;
			span {
				width: 50%;
			}
		}
		input[type="submit"] {
			font-weight: 700;
		}
	}
}

@media screen and (max-width: 576px) {
	#instructor-search-bar {
		input, select, input[type="submit"] {
			padding: 15px 35px;
			margin-top: 7px;
			margin-bottom: 7px;
			width: 100%;
			font-size: 15px;
		}
		select {
			padding: 15px 15px;
		}
		.datepicker {
			width: 100%;
			display: flex;
			span {
				width: 50%;
			}
		}
		input[type="submit"] {
			font-weight: 700;
		}
	}
}