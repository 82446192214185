footer {
	.footer-top {
		background-color: #070b1b;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		ul {
			padding: 0;
			margin: 0;
			list-style-type: none;
			li a {
				color: $textGreyPurple;
				font-size: 18px;
				font-weight: 300;

			}
			li.bolded a {
				color: white;
			}
			li {
				color: #757987;
				padding-bottom: 20px;
			}
		}
		.logo {
			max-width: 130px;
		}
	}
	.bottom-footer {
		background-color: $companyBlack;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 40px;
		padding-bottom: 40px;
		ul {
			padding: 0;
			margin: 0;
			list-style-type: none;
			li {
				color: white;
				font-size: 18px;
				font-weight: 300;
				a {
					color: $Yellow;
				}
			}
		}
		.social:first-child, .social::first-child {
			margin-right: 15px;
		}
	}
}

@media screen and (min-width: 992px) {
	footer {
		.footer-top {
			padding-top: 100px;
			padding-bottom: 100px;
			#a1 {
				order: 2;
			}
			#a2 {
				order: 3;
			}
			#a3 {
				order: 4;
			}
			#a4 {
				order: 1;
			}
		}
		.inner {
			width: 85vw;
			max-width: 1600px;
		}
	}
}

@media screen and (max-width: 991px) and (min-width: 769px) {
	footer {	
		.footer-top {
			padding-top: 40px;
			padding-bottom: 40px;
		}
		.inner {
			width: 90vw;
		}
	}
}

@media screen and (max-width: 768px) {
	footer {
		.footer-top {
			padding-top: 20px;
			padding-bottom: 20px;
		}
		.bottom-footer {
			padding-top: 20px;
			padding-bottom: 20px;
		}
		.inner {
			width: 95vw;
		}

	}
}

@media screen and (max-width: 576px) {
	footer {
		text-align: center;
		.footer-top {
			ul {
				li {
					a {
						font-size: 15px;
					}
				}
			}
		}
		.bottom-footer {
			ul {
				li {
					padding-bottom: 20px;
					font-size: 15px;
				}
			}
		}
	}
}


