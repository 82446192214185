#instructor-testimonial {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	.title {
		margin-bottom: 30px;
	}
	.inner {
		width: 85vw;
		position: relative;
		.left {
			width: 30%;
			min-width: 300px;
			p {
				font-weight: 300;
			}
			a {
				color: $textBlue;
				padding-bottom: 2px;
				font-weight: 600;
				transition: all 0.4s ease;
				&:hover {
					text-decoration: none;
					transition: all 0.4s ease;
					padding-bottom: 1px;
					border-bottom: 1px solid;
				}
			}
		}
		.testimonial-source {
			font-weight: 600;
			font-size: 15px;
			text-transform: uppercase;
		}
		.testimonial-slide-holder {
			position: relative;
		}
		.slick-arrow {
			font-weight: bold;
			color: $companyBlue;
			font-size: 48px;
			cursor: pointer;
			&.prev {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: -100px;
			}
			&.next {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: -100px;
			}
		}
	}
	.instructor {
		position: absolute;
	}
}

@media (min-width: 1400px) {
	#instructor-testimonial {
		.inner {
			width: 85vw;
			max-width: 1470px;
			.left {
				width: 40%;
				min-width: 300px;
			}
		}
	}
}

@media (min-width: 1800px) {
	#instructor-testimonial {
		padding-top: 80px;
		padding-bottom: 80px;
		.inner {
			padding: 80px;
			.instructor {
				height: 100%;
				height: calc(100% + 160px);
				top: -80px;
			}
		}
	}
}

@media (max-width: 1400px) and (min-width: 1200px) {
	#instructor-testimonial {
		padding-top: 60px;
		padding-bottom: 60px;
		.inner {
			padding: 60px;
			.instructor {
				height: 100%;
				height: calc(100% + 120px);
				top: -60px;
			}
		}
	}
}

@media (max-width: 1300px) and (min-width: 1200px) {
	#instructor-testimonial {
		.inner {
			.instructor {
				right: -80px;
			}
		}
	}
}

@media (min-width: 1200px) {
	#instructor-testimonial {
		padding-top: 60px;
		padding-bottom: 60px;
		.inner {
			padding: 40px;
			.instructor {
				height: 100%;
				height: calc(100% + 120px);
				top: -60px;
				right: 0;
			}
		}
	}
}

@media (max-width: 1199px) and (min-width: 992px) {
	#instructor-testimonial {
		padding-top: 40px;
		padding-bottom: 40px;
		.inner {
			padding: 40px;
			.instructor {
				height: 100%;
				height: calc(100% + 80px);
				top: -40px;
				right: -165px;
			}
		}
	}
}

@media (max-width: 991px) and (min-width: 769px) {
	#instructor-testimonial {
		padding-top: 40px;
		padding-bottom: 40px;
		.inner {
			width: 90vw;
			padding: 40px;
			p {
				font-size: 16px;
			}
			.instructor {
				height: 100%;
				height: calc(100% + 80px);
				top: -40px;
				right: 0;
			}
		}
	}
}

@media (max-width: 768px) and (min-width: 577px) {
	#instructor-testimonial {
		padding-top: 20px;
		padding-bottom: 20px;
		.inner {
			width: 95vw;
			padding: 20px;
			p {
				font-size: 16px;
			}
			.instructor {
				height: 100%;
				height: calc(100% + 40px);
				top: -20px;
				right: 0;
			}
		}
	}
}

@media (max-width: 768px) and (min-width: 622px) {
	#instructor-testimonial {
		padding-top: 20px;
		padding-bottom: 20px;
		.inner {
			.left {
				width: 50%;
			}
		}
	}
}

@media (max-width: 622px) and (min-width: 577px) {
	#instructor-testimonial {
		padding-top: 20px;
		padding-bottom: 20px;
		.inner {
			.left {
				width: 30%;
			}
			.instructor {
				top: 0;
				height: 100%;
			}
		}
	}
}

@media (max-width: 576px) {
	#instructor-testimonial {
		padding-top: 20px;
		padding-bottom: 0px;
		overflow-x: hidden;
		.inner {
			width: 95vw;
			padding: 20px;
			margin-bottom: 366px;
			.left {
				width: 100%;
			}
			p {
				font-size: 15px;
			}
			.instructor {
				width: 372px;
				right: 0px;
				right: calc(50% - 170px);
				top: 100%;
			}
		}
	}
}