#international-arrow {
	margin-top: 15px;margin-bottom:15px;display: block;color:#f5e22a;cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
}
#instructor-course-search {
	.refine-search {
		text-align: center;
		img {
			width: 50%;
			max-width: 50px;
		}
	}
	#instructor-search-results {
		.result {
			width: 100%;
			border-bottom: 1px solid #e6e6e6;
			&:hover {
				text-decoration: none;
				transition: all 0.4s ease;
				background-color: rgba(98, 134, 254, 0.1);
				.arrow {
					padding-right: 5px;
				}
			}
		}
		.instructor-picture {
			text-align: center;
			display: flex;
			img {
				object-fit: cover;
				object-position: center;
				overflow: hidden;
				margin: auto;
				justify-content: center;
				align-items: center;
			}
		}
		.date {
			color: $companyBlue;
			font-weight: 400;
		}
		.location {
			color: $textGrey;
			font-weight: 400;
		}
		.course-name {
			color: $companyBlue;
			font-weight: 600;
		}
		.arrow {
			color: $companyBlue;
			transition: all 0.4s ease;
			padding-right: 15px;
			font-weight: 900;
		}
	}
	.kids-banner {
		.subtitle, .price {
			color: $Yellow;
		}
		.title, .desc {
			color: white;
		}
		.title {
			text-transform: uppercase;
		}
		.subtitle {
			text-transform: uppercase;
			font-weight: 600;
		}
	}
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
	#instructor-course-search {
		.kids-banner {
			padding: 60px;
		}
	}
}

@media screen and (min-width: 992px) {
	#instructor-course-search {
		#instructor-search-results {
			.result {
				display: flex;
				align-items: center;
				justify-content: left;
				text-align: left;
				padding: 10px 5px;
				& > div {
					overflow: hidden;
					font-size: 21px;
				}
				.instructor-picture {
					flex: 7;
					img {
						width: 50px;
						height: 50px;
						border-radius: 25px;
					}
				}
				.date {
					flex: 14;
				}
				.location {
					flex: 14;
				}
				.course-name {
					flex: 55;
				}
				.arrow {
					font-size: 18px;
					white-space: nowrap;
					a {
						padding: 14px 23px;
					}
				}
			}
		}
		.kids-banner {
			padding: 109px 140px;
			.text {
				width: 70%;
				max-width: 800px;
				min-width: 300px;
			}
			.title {
				font-size: 60px;
				margin-bottom: 30px;
			}
			.desc {
				margin-bottom: 30px;
			}
			.price {
				font-size: 24px;
				margin-bottom: 0;
			}
		}
	}
}

@media screen and (max-width: 991px) and (min-width: 577px) {
	#instructor-course-search {
		#instructor-search-results {
			.result {
				display: flex;
				align-items: center;
				justify-content: left;
				text-align: left;
				flex-wrap: wrap;
				padding: 10px 5px;
				position: relative;
				& > div {
					font-size: 21px;
				}
				.instructor-picture {
					width: 100px;
					position: relative;
					img {
						position: absolute;
						top: -18px;
						top: calc(50% - 15px);
						left: 15px;
						width: 60px;
						height: 60px;
						border-radius: 30px;
					}
				}
				.date {
					width: 100px;
				}
				.course-name {
					padding-left: 100px;
					width: 100%;
				}
				.arrow {
					position: absolute;
					right: 15px;
					top: 11px;
					top: calc(50% - 11px);
				}
			}
		}
		.kids-banner {
			padding: 60px;
			.title {
				font-size: 60px;
				margin-bottom: 30px;
			}
			.desc {
				margin-bottom: 30px;
			}
			.price {
				font-size: 24px;
				margin-bottom: 0;
			}
		}
	}
}

@media screen and (max-width: 576px) {
	#instructor-course-search {
		#instructor-search-results {
			.result {
				display: flex;
				align-items: center;
				justify-content: left;
				text-align: left;
				flex-wrap: wrap;
				padding: 10px 5px;
				position: relative;
				& > div {
					font-size: 15px;
				}
				.instructor-picture {
					width: 50px;
					position: relative;
					img {
						position: absolute;
						top: 12px;
						top: calc(50% - 11px);
						left: 0;
						width: 40px;
						height: 40px;
						border-radius: 20px;
					}
				}
				.date {
					width: 60px;
				}
				.course-name {
					padding-left: 50px;
					width: 100%;
				}
				.arrow {
					position: absolute;
					right: 15px;
					top: 11px;
					top: calc(50% - 11px);
				}
			}
		}
		.kids-banner {
			padding: 60px;
			.subtitle {
				font-size: 15px;
			}
			.title {
				font-size: 2rem;
				margin-bottom: 30px;
			}
			.desc {
				font-size: 15px;
				margin-bottom: 30px;
			}
			.price {
				font-size: 18px;
				margin-bottom: 0;
			}
		}
	}
}

@media screen and (max-width: 400px) {
	#instructor-course-search {
		#instructor-search-results {
			.result {
				.instructor-picture {
					display: none;
				}
				.course-name {
					padding-left: 0px;
				}
			}
		}
	}
}