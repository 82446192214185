#boxercise-instructor-course-page {
	.full-header {
		width: 100%;
		padding-top: 150px;
		padding-bottom: 150px;
		background-size: cover;
		background-repeat: no-repeat;
		.text {
			.subheading {
				color: $Yellow;
				text-transform: uppercase;
				font-size: 21px;
				font-weight: 600;
				margin-bottom: 5px;
				line-height: 1em;
			}
			h1 {
				color: white;
				font-size: 60px;
				font-weight: 400;
			}
		}
	}
	.specific-course-info {
		.level {
			color: $companyBlue;
			text-transform: uppercase;
			font-weight: 600;
		}
		h1 {
			text-transform: uppercase;
			margin-bottom: 16px;
		}
		h3, a, .contact-block {
			width: 100%;
			max-width: 360px;
		}
		h3 {
			color: $companyBlue;
			text-transform: uppercase;
			font-family: 'SteelFish', sans-serif;
			margin-bottom: 30px;
		}
		.contact-block {
			color: $companyBlue;
			padding-top: 33px;
			padding-bottom: 33px;
			border-top: 2px solid #ddd;
			font-weight: 600;
			font-size: 18px;
			.icon {
				display: inline-block;
				margin-right: 20px;
			}
		}

	}
	.right-column {
		img {
			width: calc(25% - 30px);
			margin:  20px 15px;
		}
	}
	.desc {
		h3, h4, h5 {
			color: $companyBlue;
			font-size: 30px;
			margin-top: 30px;
			text-transform: uppercase;
			font-family: 'SteelFish', sans-serif;
			margin-bottom: 15px;
		}
		ul {
			color: $textGrey;
			font-size: 18px;
			line-height: 1.66em;
			li {
				margin-bottom: 15px;
				padding-left: 15px;
			}
		}
	}
	.day-structure {
		h3 {
			font-family: 'SteelFish', sans-serif;
			color: $companyBlue;
			font-size: 36px;
			margin-bottom: 60px;
		}
		.text.icon {
			font-size: 36px;
		}
		.text {
			font-size: 21px;
			font-weight: 700;
			color: $companyBlue;
		}
		ul {
			list-style-type: none;
			border-left: 1px solid #ddd;
			padding-left: 30px;
			margin-left: 0;
			li {
				padding: 10px 0;
			}
			li:first-child {
				padding-top: 0;
			}
			.title {
				font-size: 21px;
				font-weight: 700;
				color: $companyBlue;
			}
			.desc {
				font-size: 18px;
				color: $textGrey;
				font-weight: 400;
			}
		}
	}
	#instructor-whats-next-block {
		h3 {
			color: white;
			font-family: 'SteelFish', sans-serif;
			font-size: 60px;
			text-transform: uppercase;
		}
		p {
			color: white;
		}
		ul {
			list-style-type: none;
			padding: 0;
			margin: 0;
			margin-top: 40px;
		}
		ul.main {
			li {
				a {
					display: block;
					color: $textBabyPurple;
					font-size: 21px;
					border-bottom: 1px solid #4b5987;
					padding-bottom: 25px;
					padding-top: 25px;
					&.active {
						color: $Yellow;
						border-bottom: 2px solid $Yellow;
					}
				}
			}
		}
		.stretched {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}
	.extra-course {
		display: flex;
		align-items: flex-start;
		margin-bottom: 15px;
		transition: all 0.8s ease;
		padding-bottom: 15px;
		border-bottom: 1px solid #ccc;
		img {
			width: 30%;
			max-width: 150px;
		}
		.content {
			padding: 10px;
		}
		&:hover {
			text-decoration: none;
			box-shadow: 0 25px 73px 0 rgba(24,24,24,0.25);
			transform: scale(1.01);
		}
	}
}

#course-map {
	height: calc(100% - 60px);
	max-height: 350px;
	width: 100%;
	max-width: 360px;
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
	#boxercise-instructor-course-page {
		#instructor-whats-next-block {
			#desktop-course-holder {
				.course {
					margin-left: 20px;
					margin-right: 20px;
				}
			}
		}
	}
}

@media screen and (max-width: 1199px) {
	#boxercise-instructor-course-page {
		#instructor-whats-next-block {
			ul.main {
				li {
					a {
						font-size: 18px;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 991px) {
	.specific-course-info {
		h1 {
			font-size: 60px;
		}
		h3 {
			font-size: 36px;
		}
	}
	#boxercise-instructor-course-page {
		#instructor-whats-next-block {
			#desktop-course-holder {
			display: flex;
			align-items: stretch;
			.course {
				background-color: white;
				margin-left: 50px;
				margin-right:50px;
				width: 400px;
				overflow: hidden;
				img {
					height: 260px;
				}
				.text {
					padding: 50px;
					text-transform: uppercase;
					color: $companyBlue;
					.subheading {
						font-size: 18px;
						margin-top: 0;
						line-height: 1em;
						font-family: 'SteelFish', sans-serif;
					}
					.title {
						font-size: 36px;
						font-family: 'SteelFish', sans-serif;
					}
				}
			}
			.pop-animation, .pop-animation * {
				animation-name: pop-animation;
				animation-duration: 0.4s;
				animation-fill-mode: forwards;
			}
		}
			.secondary {
				display: none !important;
			}
		}
	}
}

@media screen and (max-width: 991px) {
	#boxercise-instructor-course-page {
		.full-header {
			padding-top: 200px;
			padding-bottom: 75px;
		}
		#instructor-whats-next-block {
			#desktop-course-holder {
				display: none !important;
			}
			.main{
				li a {
					position: relative;
					transform: all ease 1.2s;
					&:after {
						position: absolute;
						content: "˅";
						font-size: 24px;
						right: 5px;
						top: 20px;
						top: calc(50% - 15px);
						background-color: $companyBlue;
						color: white;
					}
				}
				li a.rotateAfter {
					&:after {
						top: calc(50% - 23px);
						transform: rotate(180deg);
					}
				}
			}
			.secondary {
				overflow-x: scroll;
				display: flex;
				.course {
					flex-grow: 0;
					flex-shrink: 0;
					max-width: 350px;
					min-width: 200px;
					width: 80%;
					overflow: hidden;
					margin-right: 30px;
					background-color: white;
					margin-bottom: 30px;
					img {
						height: 250px;
					}
					.text {
						padding: 30px;
						text-transform: uppercase;
						color: $companyBlue;
						.subheading {
							font-size: 12px;
							margin-top: 0;
							line-height: 1em;
							font-family: 'SteelFish', sans-serif;
						}
						.title {
							font-size: 21px;
							font-family: 'SteelFish', sans-serif;
						}
					}
				}
			}
		}
	}
			
}

@media screen and (max-width: 576px) {
	#boxercise-instructor-course-page {
		.full-header {
			padding-top: 50px;
			padding-bottom: 50px;
			h1.title {
				font-size: 2.5rem;
			}
		}
		.day-structure {
			h3 {
				margin-top: 50px;
				margin-bottom: 10px;
			}
			span.text {
				padding-left: 30px;
			}
			ul {
				margin-left: 0;
				.title {
					font-size: 15px;
				}
				.desc {
					font-size: 12px;
				}
			}
		}
		#instructor-whats-next-block {
			padding-top: 50px;
			padding-bottom: 50px;
			h3 {
				font-size: 2.5rem;
			}
			ul.main {
				margin-top: 0px;
				li {
					a {
						font-size: 15px;
					}
				}
			}
		}
	}
}

@keyframes pop-animation {
	0% {
		opacity: 0;
		transform: scale(1);
	}
	30% {
		transform: scale(1.2);
		opacity: 0.5;
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}